import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form";
import ui from "./ui";
import auth from "./auth";
import user from "./user";
import history from "./history";
import device_type from "./device_type";
import version from "./version";
import device from "./device";
import channel from "./channel";
import manual from "./manual";
import report from "./report";
import group from "./group";
import notification from "./notification";
import configvalue from "./configvalue";
import temfile  from "./temfile";



const rootReducer = combineReducers({
  ui,
  form: formReducer,
  auth,
  user,
  report,
  history,
  device_type,
  version,
  device,
  channel,
  manual,
  group,
  notification,configvalue, temfile
});

export default rootReducer;
